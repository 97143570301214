import { defineStore } from 'pinia'
import type { Post, ApiResponse } from '~/types/Post'


export const usePostsStore = defineStore('posts', {
  state: () => ({
    posts: [] as Post[],
    currentPage: 1,
    lastPage: 1,
    loading: false,
  }),

  actions: {
    parseHtmlContent(html: string): string {
      return html.replace(
        /<img([^>]*)src="([^"]*)"([^>]*)>/gi,
        '<NuxtImg$1src="$2"$3 loading="lazy" placeholder="blur">'
      )
    },

    async fetchPosts() {
      if (this.loading || this.currentPage > this.lastPage) return
      
      this.loading = true
      try {
        const config = useRuntimeConfig();
        const response = await $fetch<ApiResponse>(
          `${config.public.sanctum.baseUrl}/api/posts?page=${this.currentPage}`
        )
        
        // Procesar el contenido HTML de cada post antes de almacenarlo
        const parsedPosts = response.data.map(post => ({
          ...post,
          content: this.parseHtmlContent(post.content)
        }))
        
        this.posts.push(...parsedPosts)
        this.currentPage = response.meta.current_page + 1
        this.lastPage = response.meta.last_page
      } catch (error) {
        console.error('Error fetching posts:', error)
      } finally {
        this.loading = false
      }
    },
  },
})
